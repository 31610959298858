import React from 'react';
import TextField from '@material-ui/core/TextField';
import Alert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';
import { withStyles } from '@material-ui/core/styles';
import Fade from 'react-reveal/Fade';
import Button from '../button';

import styles from './index.module.css';

const useStyles = {
  root: {
    '& label.Mui-focused': {
      color: '#bd2026',
    },
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        borderColor: '#bd2026',
      },
    },
    '@media (max-width: 1003px)': {
      margin: 0,
    },
  },
  field: {
    display: ' flex',
    flexDirection: 'column',
    margin: '25px 0px',
  },
  button: {
    fontFamily: 'Arial Black, serif',
    letterSpacing: '2px',
    fontSize: '15px',
    border: 'none',
    padding: '15px',
    color: 'white',
    backgroundColor: '#191f23',
    textAlign: 'center',
    cursor: 'pointer',
    width: '100%',

    '&:hover': {
      opacity: '0.7',
    },
  },
};

const encode = data => {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&');
};

class ConsignmentForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      firstName: '',
      lastName: '',
      email: '',
      comment: '',
      formSubmit: false,
      formError: false,
      open: false,
      message: '',
    };
  }

  // handleClick = () => {
  //   this.setState(prevState => ({ open: !prevState.open }));
  // };

  handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState(prevState => ({ open: !prevState.open }));
  };

  handleSubmit = e => {
    const status =
      'We appreciate you contacting us. We will get back in touch with you soon!';
    const errorMessage = 'Unable to submit form. Please try again.';
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({ 'form-name': 'consignment', ...this.state }),
    })
      .then(() => {
        this.setState({
          message: status,
          formSubmit: true,
          open: true,
        });
      })
      .catch(() => {
        this.setState({ message: errorMessage, formError: true });
      });
    e.preventDefault();
  };

  handleChange = e => this.setState({ [e.target.name]: e.target.value });

  render() {
    const { classes } = this.props;
    const { open, formError, message } = this.state;

    return (
      <div className={styles.formContainer}>
        <form
          className={classes.root}
          name="consignment"
          method="post"
          data-netlify="true"
          data-netlify-honeypot="bot-field"
          onSubmit={this.handleSubmit}
        >
          <input type="hidden" name="form-name" value="consignment" />
          <input type="hidden" name="bot-field" />
          <Fade bottom cascade>
            <div style={{ margin: '10px 0px' }}>
              <div className={styles.title}>MAKE AN INQUIRY</div>
              <div className={styles.line} />
            </div>
            <div>
              <TextField
                className={classes.field}
                name="firstName"
                type="text"
                label="First Name"
                variant="outlined"
                onChange={this.handleChange}
                InputLabelProps={{ required: false }}
                required
              />
              <TextField
                className={classes.field}
                name="lastName"
                type="text"
                id="outlined-basic"
                label="Last Name"
                variant="outlined"
                onChange={this.handleChange}
                InputLabelProps={{ required: false }}
                required
              />
              <TextField
                className={classes.field}
                name="email"
                type="email"
                label="Email"
                variant="outlined"
                onChange={this.handleChange}
                InputLabelProps={{ required: false }}
                required
              />
              <TextField
                className={classes.field}
                name="comment"
                type="text"
                multiline
                label="Tell us more about your vehicle"
                variant="outlined"
                onChange={this.handleChange}
                InputLabelProps={{ required: false }}
                required
              />
            </div>
            <Button className={classes.button} type="submit" text="SUBMIT" />
          </Fade>
          <Snackbar
            open={open}
            autoHideDuration={6000}
            onClose={this.handleClose}
          >
            {formError ? (
              <Alert onClose={this.handleClose} severity="error">
                {message}
              </Alert>
            ) : (
              <Alert onClose={this.handleClose} severity="success">
                {message}
              </Alert>
            )}
          </Snackbar>
        </form>
      </div>
    );
  }
}
export default withStyles(useStyles)(ConsignmentForm);
