import { graphql, useStaticQuery } from 'gatsby';

const useQuery = () => {
  const query = useStaticQuery(
    graphql`
      query {
        consignmentPageImage: contentfulContentMedia(
          contentful_id: { eq: "32mfYmxPpYd7qoLYCqFRTZ" }
        ) {
          media {
            file {
              url
              fileName
              contentType
            }
          }
        }
        consignmentCard: contentfulCard(
          contentful_id: { eq: "OhaS8UZ5lrP0uiBJsrDyi" }
        ) {
          title
          subtitle
          description {
            description
          }
          buttonText
        }
        testimonial: allContentfulTestimonials {
          edges {
            node {
              id
              author
              quote {
                quote
              }
            }
          }
        }
      }
    `,
  );
  return query;
};

export default useQuery;
