import React from 'react';
import FormatQuoteIcon from '@material-ui/icons/FormatQuote';

import styles from './index.module.css';

const TestimonialCard = props => {
  const { author, quote } = props;

  return (
    <div className={styles.container}>
      <FormatQuoteIcon style={{ fontSize: '60px', marginBottom: '20px' }} />
      <div className={styles.quote}>{quote}</div>
      <div className={styles.author}>{author}</div>
    </div>
  );
};

export default TestimonialCard;
