import React from 'react';

import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import Fade from 'react-reveal/Fade';
import Layout from '../components/layout';
import SEO from '../components/seo';
import Form from '../components/form/form';

import Card from '../components/card';

import TestimonialCard from '../components/testimonial-card';

import styles from './consignment.module.css';

import useQuery from '../queries/consignment-query';

const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 1,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 1,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

const Consignment = () => {
  const { consignmentPageImage, consignmentCard, testimonial } = useQuery();

  return (
    <Layout>
      <SEO title="Consignment" />
      <div className={styles.consignmentContentContainer}>
        <Fade left duration={3000}>
          <img
            className={styles.consignmentImage}
            src={consignmentPageImage.media.file.url}
            alt="consignment"
          />
        </Fade>
        <Fade right duration={3000}>
          <div className={styles.consignmentContentCard}>
            <Card
              className={styles.consignmentCard}
              title={consignmentCard.title}
              subtitle={consignmentCard.subtitle}
              desc={consignmentCard.description.description}
            />
          </div>
        </Fade>
      </div>
      <div className={styles.carouselContainer}>
        <Carousel
          infinite
          autoPlay
          autoPlaySpeed={3000}
          transitionDuration={500}
          responsive={responsive}
          containerClass={styles.containerClass}
          itemClass={styles.itemClass}
          arrows={false}
        >
          {testimonial.edges.map(edge => {
            return (
              <div key={edge.node.id}>
                <TestimonialCard
                  author={edge.node.author}
                  quote={edge.node.quote.quote}
                />
              </div>
            );
          })}
        </Carousel>
      </div>
      <div className={styles.formContainer}>
        <Form />
      </div>
    </Layout>
  );
};

export default Consignment;
